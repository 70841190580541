<script>
  import { onMount, setContext, getContext } from "svelte";
  import I18n from "../../utilities/I18n.js";

  import Button from "sveltestrap/src/Button.svelte";
  import FormInput from "../generic/form/FormInput.svelte";
  import FileUpload from "../generic/form/FileUpload.svelte";
  import LightingConfig from "./LightingConfig.svelte";
  import EnergyBalanceTable from "./EnergyBalanceTable.svelte";

  export let code, mode, version, editable, building, roomGroup, debug;

  const lighttool = mode === "lighttool",
    v1 = version == "v1",
    lightcheck = mode === "lightcheck",
    lighttune = mode === "lighttune";

  const readonly = !editable;

  setContext("context", {
    code,
    lighttool,
    lightcheck,
    lighttune,
    v1,
    editable,
    building,
    roomGroup,
    version,
  });

  let usages = [];
  let usageTypes = [];
  let energyBalance = {};

  let luminaireTypes = [];
  let configAttributes = {};
  let submitting = false;
  let errors = [];

  let assessments = [];

  let windowless = false;

  const submitRoomGroupForm = () => {
    submitting = true;
    errors = [];
    const form = document.querySelector("form#roomgroupform");
    const request = new XMLHttpRequest();

    if (roomGroup.id) {
      request.open("PUT", roomGroup.update_path);
    } else {
      request.open("POST", roomGroup.new_path);
    }

    request.onload = (e) => {
      let res;
      submitting = false;
      if (request.status === 200) back();
      else
        try {
          res = JSON.parse(request.response);
          errors = res.error.length > 0 ? res.error : [I18n.t("lighttool.application.alert.saving_failed")];
        } catch (e) {
          errors = [I18n.t("lighttool.application.alert.saving_failed")];
        } finally {
          document.getElementById("page_top").scrollIntoView({ behaviour: "smooth" });
        }
    };
    request.send(new FormData(form));
  };

  const submit = async (url = "", data = {}, method) => {
    const response = await fetch(url, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    return await response.json();
  };

  const filterNullProperties = (obj) => {
    let filtered = {};
    for (const prop in obj) {
      if (obj[prop]) {
        if (typeof obj[prop] === "object") filtered[prop] = filterNullProperties(obj[prop]);
        else filtered[prop] = obj[prop];
      }
    }
    return filtered;
  };

  const loadRoomGroup = async (id) => {
    const response = await fetch(roomGroup.path);
    const data = await response.json();
    roomGroup = data;
    handleWindowSelect();
    calculateEnergyBalance();
  };

  const handleWindowSelect = (e) => {
    if (roomGroup.lighttool_window_area_id === 1) {
      windowless = true;

      roomGroup.lighttool_shading_id = 1;
      roomGroup.new_config_attributes.lighttool_solar_protection_type_id = 6;
      roomGroup.new_config_attributes.lighttool_solar_protection_control_id = 5;
      roomGroup.new_config_attributes.lighttool_daylight_control_id = 5;

      if (lighttool && roomGroup.old_config_attributes) {
        roomGroup.old_config_attributes.lighttool_solar_protection_type_id = 6;
        roomGroup.old_config_attributes.lighttool_solar_protection_control_id = 5;
        roomGroup.old_config_attributes.lighttool_daylight_control_id = 5;
      }
      if (lightcheck) {
        roomGroup.check_attributes.configuration_attributes.lighttool_solar_protection_type_id = 6;
        roomGroup.check_attributes.configuration_attributes.lighttool_solar_protection_control_id = 5;
        roomGroup.check_attributes.configuration_attributes.lighttool_daylight_control_id = 5;
      }
      if (lighttune) {
        roomGroup.optimization_attributes.configuration_attributes.lighttool_solar_protection_type_id = 6;
        roomGroup.optimization_attributes.configuration_attributes.lighttool_solar_protection_control_id = 5;
        roomGroup.optimization_attributes.configuration_attributes.lighttool_daylight_control_id = 5;
      }
    } else windowless = false;

    calculateEnergyBalance();
  };

  const calculateEnergyBalance = async (e) => {
    console.debug(filterNullProperties(roomGroup));
    let req = submit(roomGroup.calculation_path, { room_group: filterNullProperties(roomGroup), [`${csrfparam}`]: csrftoken }, "POST").then(
      (res) => {
        energyBalance = res;
      },
      (res) => (energyBalance = {}),
    );
  };

  const copyOldConfig = () => {
    // overwrite new config values with old config
    for (const [prop, value] of Object.entries(roomGroup.old_config_attributes)) {
      if (prop !== "id" && prop !== "luminaires_attributes") roomGroup.new_config_attributes[prop] = value;
    }
    // remove (or mark-for-deletion) current luminaires from new config
    for (let i = roomGroup.new_config_attributes.luminaires_attributes.length - 1; i >= 0; i--) {
      if (roomGroup.new_config_attributes.luminaires_attributes[i].id) roomGroup.new_config_attributes.luminaires_attributes[i]._destroy = true;
      else roomGroup.new_config_attributes.luminaires_attributes.splice(i, 1);
    }
    // add all luminaires from old config to new
    for (const luminaire of roomGroup.old_config_attributes.luminaires_attributes) {
      const newLuminaire = { ...luminaire };
      delete newLuminaire.id;
      roomGroup.new_config_attributes.luminaires_attributes.push({ ...newLuminaire });
    }
    roomGroup.new_config_attributes = roomGroup.new_config_attributes;
    calculateEnergyBalance();
  };

  const back = () => {
    window.location.href = roomGroup.return_path;
  };

  let fileDragging = false;
  let fileError = false;

  let csrfparam = "authenticity-token";
  let csrftoken = "";

  onMount(async () => {
    try {
      csrftoken = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-token")[0]
        .getAttribute("content");
      csrfparam = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-param")[0]
        .getAttribute("content");
    } catch (e) {
      console.error(e);
    }

    let response = await fetch(roomGroup.usages_path);
    usages = await response.json();

    response = await fetch(roomGroup.usage_types_path);
    usageTypes = await response.json();

    response = await fetch(roomGroup.luminaire_types_path);
    luminaireTypes = await response.json();

    response = await fetch(roomGroup.configuration_attributes_path);
    configAttributes = await response.json();

    response = await fetch(roomGroup.assessments_path);
    assessments = await response.json();

    if (roomGroup.id) await loadRoomGroup(roomGroup.id);
  });

  $: usage = roomGroup.lighttool_usage_id ? usages.filter((u) => u.id == roomGroup.lighttool_usage_id)[0] : null;
  $: illuminance = usage ? usage.illuminance : null;
  $: illuminance_uniformity =
    roomGroup.check_attributes.illuminance_min > 0 && roomGroup.check_attributes.illuminance_med > 0
      ? Math.round((10 * roomGroup.check_attributes.illuminance_min) / roomGroup.check_attributes.illuminance_med) / 10
      : "";
</script>

<form id="roomgroupform" on:submit|preventDefault={submitRoomGroupForm} enctype="multipart/form-data">
  <div id="alert-container">
    {#each errors as e}
      <div class="alert alert-warning">{e}</div>
    {/each}
  </div>

  <div class="row">
    <fieldset class="group col-xs-12">
      <legend><h3>{I18n.t("lighttool.room_group.properties")}</h3></legend>
      <div class="row">
        <FormInput name="room_group[name]" label={I18n.t("lighttool.room_group.name")} bind:value={roomGroup.name} disabled={readonly || lighttune} />
        <FormInput
          name="room_group[area]"
          label={I18n.t("lighttool.room_group.area")}
          bind:value={roomGroup.area}
          addon="m&sup2;"
          disabled={readonly || lighttune}
          on:change={calculateEnergyBalance} />
        <FormInput
          type="select"
          name="room_group[lighttool_usage_id]"
          label={I18n.t("lighttool.room_group.usage")}
          bind:value={roomGroup.lighttool_usage_id}
          options={usages}
          disabled={readonly || lighttune}
          on:change={calculateEnergyBalance} />
        <FormInput label={I18n.t("lighttool.room_group.illuminance")} addon="lx" bind:value={illuminance} disabled />
        <FormInput
          type="select"
          name="room_group[lighttool_window_area_id]"
          label={I18n.t("lighttool.room_group.window_area")}
          options={configAttributes.window_area}
          bind:value={roomGroup.lighttool_window_area_id}
          on:change={handleWindowSelect}
          disabled={readonly || lighttune} />
        <FormInput
          type="select"
          name="room_group[lighttool_shading_id]"
          label={I18n.t("lighttool.room_group.shading")}
          options={configAttributes.shading}
          bind:value={roomGroup.lighttool_shading_id}
          on:change={calculateEnergyBalance}
          readonly={windowless}
          disabled={readonly || lighttune} />
      </div>
    </fieldset>
    {#if lightcheck}
      <fieldset class="group col-xs-12">
        <legend><h3>{I18n.t("lighttool.room_group.controlling")}</h3></legend>
        <div class="row">
          <input type="hidden" name="room_group[check_attributes][id]" value={roomGroup.check_attributes.id || null} />
          <FormInput name="room_group[check_attributes][name]" label={I18n.t("lighttool.room_group.check.name")} bind:value={roomGroup.check_attributes.name} {readonly} />
          <FormInput
            name="room_group[check_attributes][area]"
            label={I18n.t("lighttool.room_group.check.area")}
            bind:value={roomGroup.check_attributes.area}
            addon="m&sup2;"
            {readonly}
            on:change={calculateEnergyBalance} />
          <FormInput
            name="room_group[check_attributes][illuminance_max]"
            label={I18n.t("lighttool.room_group.check.illuminance_max")}
            addon="lx"
            bind:value={roomGroup.check_attributes.illuminance_max}
            {readonly} />
          <FormInput
            name="room_group[check_attributes][illuminance_min]"
            label={I18n.t("lighttool.room_group.check.illuminance_min")}
            addon="lx"
            bind:value={roomGroup.check_attributes.illuminance_min}
            {readonly} />
          <FormInput
            name="room_group[check_attributes][illuminance_med]"
            label={I18n.t("lighttool.room_group.check.illuminance_med")}
            addon="lx"
            bind:value={roomGroup.check_attributes.illuminance_med}
            {readonly} />
          <FormInput label={I18n.t("lighttool.room_group.check.illuminance_uniformity")} value={illuminance_uniformity} readonly />
        </div>
      </fieldset>
    {/if}
  </div>

  <div class="row group-container">
    {#if lighttool && v1}
      <fieldset class="group col-xs-12">
        <legend><h3>{I18n.t("lighttool.room_group.lighting_old")}</h3></legend>
        <div class="row">
          <LightingConfig
            name="room_group[old_config_attributes]"
            bind:config={roomGroup.old_config_attributes}
            bind:configAttributes
            bind:luminaireTypes
            bind:windowless
            {readonly}
            on:change={calculateEnergyBalance} />
        </div>
      </fieldset>
    {/if}

    {#if lighttool || lightcheck}
      <fieldset class="group col-xs-12">
        <legend>
          <h3 style="display: inline-block">{I18n.t("lighttool.room_group.lighting_new")}</h3>
          {#if lighttool && roomGroup.old_config_attributes}<Button type="button" size="sm" on:click={copyOldConfig} style="margin-left: 10px"
              ><span class="glyphicon glyphicon-import" /> {I18n.t("lighttool.room_group.lighting_new_copy")}</Button>
          {/if}
        </legend>
        <div id="new-config-container">
          <div class="row">
            <LightingConfig
              name="room_group[new_config_attributes]"
              bind:config={roomGroup.new_config_attributes}
              bind:configAttributes
              bind:luminaireTypes
              bind:windowless
              {readonly}
              on:change={calculateEnergyBalance} />
          </div>
        </div>
      </fieldset>
    {/if}

    {#if lightcheck || lighttune}
      <fieldset class="group col-xs-12">
        <legend><h3>{I18n.t("lighttool.room_group.lighting_check")}</h3></legend>
        <div class="row">
          <input type="hidden" name="room_group[check_attributes][id]" value={roomGroup.check_attributes.id || null} />
          <LightingConfig
            name="room_group[check_attributes][configuration_attributes]"
            bind:config={roomGroup.check_attributes.configuration_attributes}
            bind:configAttributes
            bind:luminaireTypes
            bind:windowless
            {readonly}
            on:change={calculateEnergyBalance}>
            <div slot="illuminance_med">
              {#if lighttune}
                <FormInput
                  name="room_group[check_attributes][illuminance_med]"
                  label={I18n.t("lighttool.room_group.check.illuminance_med")}
                  addon="lx"
                  bind:value={roomGroup.check_attributes.illuminance_med}
                  {readonly}
                  fullwidth />
              {/if}
            </div>
          </LightingConfig>
        </div>
      </fieldset>
    {/if}
    {#if lighttune}
      <fieldset class="group col-xs-12">
        <legend><h3>{I18n.t("lighttool.room_group.lighting_optimized")}</h3></legend>
        <div class="row">
          <input type="hidden" name="room_group[optimization_attributes][id]" value={roomGroup.optimization_attributes.id || null} />
          <LightingConfig
            name="room_group[optimization_attributes][configuration_attributes]"
            bind:config={roomGroup.optimization_attributes.configuration_attributes}
            bind:configAttributes
            bind:luminaireTypes
            bind:windowless
            {readonly}
            on:change={calculateEnergyBalance}>
            <div slot="illuminance_med">
              <FormInput
                name="room_group[optimization_attributes][illuminance_med]"
                label={I18n.t("lighttool.room_group.optimization.illuminance_med")}
                addon="lx"
                bind:value={roomGroup.optimization_attributes.illuminance_med}
                {readonly}
                fullwidth />
            </div>
          </LightingConfig>
        </div>
      </fieldset>
    {/if}
  </div>

  <div class="row">
    <fieldset class="group col-xs-12">
      <legend><h3>{I18n.t("lighttool.section.energy_balance")}</h3></legend>
      <EnergyBalanceTable bind:energyBalance />
    </fieldset>
  </div>

  <div class="row">
    {#if lightcheck}
      <fieldset class="group col-md-6">
        <legend><h3>{I18n.t("lighttool.section.assessment")}</h3></legend>
        <FormInput
          fullwidth
          type="select"
          options={assessments}
          name="room_group[check_attributes][assess_luminaires_id]"
          bind:value={roomGroup.check_attributes.assess_luminaires_id}
          label={I18n.t("lighttool.room_group.check.assessment.luminaires")}
          {readonly} />
        <FormInput
          fullwidth
          type="select"
          options={assessments}
          name="room_group[check_attributes][assess_illuminance_id]"
          bind:value={roomGroup.check_attributes.assess_illuminance_id}
          label={I18n.t("lighttool.room_group.check.assessment.illuminance")}
          {readonly} />
        <FormInput
          fullwidth
          type="select"
          options={assessments}
          name="room_group[check_attributes][assess_uniformity_id]"
          bind:value={roomGroup.check_attributes.assess_uniformity_id}
          label={I18n.t("lighttool.room_group.check.assessment.uniformity")}
          {readonly} />
        <FormInput
          fullwidth
          type="select"
          options={assessments}
          name="room_group[check_attributes][assess_glaring_id]"
          bind:value={roomGroup.check_attributes.assess_glaring_id}
          label={I18n.t("lighttool.room_group.check.assessment.glaring")}
          {readonly} />
        <FormInput
          fullwidth
          type="select"
          options={assessments}
          name="room_group[check_attributes][assess_lighting_control_id]"
          bind:value={roomGroup.check_attributes.assess_lighting_control_id}
          label={I18n.t("lighttool.room_group.check.assessment.lighting_control")}
          {readonly} />
        <FormInput
          fullwidth
          type="select"
          options={assessments}
          name="room_group[check_attributes][assess_energy_demand_id]"
          bind:value={roomGroup.check_attributes.assess_energy_demand_id}
          label={I18n.t("lighttool.room_group.check.assessment.energy_demand")}
          {readonly} />
        <FormInput
          fullwidth
          type="textarea"
          name="room_group[check_attributes][assess_comment]"
          bind:value={roomGroup.check_attributes.assess_comment}
          label={I18n.t("lighttool.room_group.check.assessment.comment")}
          {readonly}
          style="height: 26rem;" />
      </fieldset>
    {/if}

    {#if lighttune}
      <fieldset class="group col-xs-12">
        <legend><h3 id="section-comment-title">{I18n.t("lighttool.section.optimization_comment")}</h3></legend>

        <FormInput
          fullwidth
          type="textarea"
          name="room_group[optimization_attributes][comment]"
          bind:value={roomGroup.optimization_attributes.comment}
          {readonly}
          style="height: 13rem;" />
      </fieldset>
    {/if}

    <fieldset class="group col-md-6">
      <legend><h3>&nbsp;</h3></legend>

      {#if lightcheck}
        {#if editable}
          <FileUpload
            name="room_group[picture_data]"
            bind:data={roomGroup.picture_data}
            bind:filename={roomGroup.picture_filename}
            bind:mime={roomGroup.picture_mime}
            style="margin-top: 10px"
            on:dragover={() => {
              fileDragging = true;
              fileError = false;
            }}
            on:dragleave={() => (fileDragging = false)}>
            {#if roomGroup.picture_data}
              <div><span class="glyphicon glyphicon-picture" />&nbsp;{roomGroup.picture_filename}</div>
              <div class="picture-wrapper">
                <img
                  src={`data:${roomGroup.picture_mime};base64, ${roomGroup.picture_data}`}
                  alt="({I18n.t('lighttool.asset.preview')} {I18n.t('lighttool.asset.failed_to_load')})" />
              </div>
            {:else}
              <div class="fileupload-placeholder" class:fileError class:fileDragging>
                <span class="glyphicon glyphicon-open" /><br />{I18n.t("lighttool.asset.name.picture")}&nbsp;{I18n.t("lighttool.asset.upload")}
              </div>
            {/if}
          </FileUpload>
        {:else if roomGroup.picture_data}
          <div><span class="glyphicon glyphicon-lamp" />&nbsp;{roomGroup.picture_filename}</div>
          <div class="picture-wrapper">
            <img src={`data:${roomGroup.picture_mime};base64, ${roomGroup.picture_data}`} alt="({I18n.t('lighttool.asset.preview')} {I18n.t('lighttool.asset.failed_to_load')})" />
          </div>
        {/if}
      {/if}
    </fieldset>
  </div>

  <div class="row">
    <fieldset class="group col-xs-12">
      <br />
      {#if editable}
        <Button type="submit" color="primary" disabled={submitting}>{I18n.t("lighttool.application.save_and_close")}</Button>
        <Button type="button" on:click={back}>{I18n.t("lighttool.application.abort")}</Button>
      {:else}
        <Button type="button" color="primary" on:click={back}>{I18n.t("lighttool.application.close")}</Button>
      {/if}
    </fieldset>
  </div>

  <input type="hidden" name={csrfparam} value={csrftoken} />
</form>

{#if debug}
  <pre class="debug mt-5">{JSON.stringify(getContext("context"), null, 2)}</pre>
{/if}

<style>
  #new-config-container {
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .picture-wrapper {
    border: 1px solid lightgray;
    width: 100%;
    padding-top: 75%;
    position: relative;
  }
  .picture-wrapper > img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .fileupload-placeholder {
    border: 1px solid grey;
    width: 100%;
    padding-top: 30%;
    padding-bottom: 30%;
    text-align: center;
  }
  .fileupload-placeholder > .glyphicon {
    font-size: 2.5em;
    margin-bottom: 0.25em;
  }
  .fileupload-placeholder.fileError {
    box-shadow: inset 0 0 12px #dc3545;
  }
  .fileupload-placeholder.fileDragging {
    box-shadow: inset 0 0 12px #999;
  }
  legend {
    border-bottom: none;
    margin: 12px 0;
  }
  .group-container {
    display: flex;
  }
</style>
