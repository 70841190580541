<script>
  import { onMount, getContext } from "svelte";
  import I18n from "../../utilities/I18n.js";

  import Table from "sveltestrap/src/Table.svelte";
  import Button from "sveltestrap/src/Button.svelte";

  import Confirm from "../generic/Confirm.svelte";

  export let room_groups;
  export let standardId;
  export let standardMet = false;

  export let energyBalance = {};

  const context = getContext("context");
  const { lighttool, lightcheck, lighttune, v1, editable, building } = context;

  onMount(async function () {
    refresh_room_groups();
  });

  const refresh_room_groups = async () => {
    const response = await fetch(building.room_groups_path);
    room_groups = await response.json();

    calculateEnergyBalance();
  };

  const calculateEnergyBalance = async () => {
    let req = submit(building.calculation_path, { building: filterPictureData(filterNullProperties(building)) }, "POST").then(
      (res) => {
        energyBalance = res;
        if (lighttool) {
          standardMet = energyBalance.standard_met;
        } else if (lightcheck) {
          standardMet = energyBalance.standard_met_check;
        } else if (lighttune) {
          standardMet = energyBalance.standard_met_optimization;
        }
      },
      (res) => {
        energyBalance = {};
      },
    );
    calculateRoomGroupRequirements(standardId);
  };

  const confirmDestroy = async (itemURL) => {
    const response = await destroy(itemURL, { _method: "delete" }, "POST");
    // console.log(response)
    refresh_room_groups();
  };

  const destroy = async (url = "", data = {}, method) => {
    let csrfparam = "authenticity-token";
    let csrftoken;
    try {
      csrftoken = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-token")[0]
        .getAttribute("content");
      csrfparam = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-param")[0]
        .getAttribute("content");
    } catch (e) {
      console.error(e);
    }

    var formdata = encodeURIComponent(csrfparam) + "=" + encodeURIComponent(csrftoken);
    for (const key in data) {
      formdata += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    }

    const response = await fetch(url, {
      method: method,
      headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
      body: formdata,
    });
    return await response;
  };

  const submit = async (url = "", data = {}, method) => {
    let csrfparam = "authenticity-token";
    let csrftoken;
    try {
      csrftoken = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-token")[0]
        .getAttribute("content");
      csrfparam = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-param")[0]
        .getAttribute("content");
    } catch (e) {
      console.error(e);
    }
    data[csrfparam] = csrftoken;

    const response = await fetch(url, {
      method: method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    return await response.json();
  };

  const filterNullProperties = (obj) => {
    let filtered = {};
    for (const prop in obj) {
      if (obj[prop]) {
        if (typeof obj[prop] === "object") filtered[prop] = filterNullProperties(obj[prop]);
        else filtered[prop] = obj[prop];
      }
    }
    return filtered;
  };

  $: calculateEnergyBalance(standardId);

  const calculateRoomGroupRequirements = (standardId) => {
    switch (standardId) {
      case 1:
        // SIA Grenzwert
        for (const rg of room_groups) {
          rg.requirement = rg.limit_value;
        }
        break;
      case 2:
        // SIA Zielwert
        for (const rg of room_groups) {
          rg.requirement = rg.target_value;
        }
        break;
      case 3:
        // Minergie
        for (const rg of room_groups) {
          rg.requirement = Math.round((100 * (rg.limit_value + rg.target_value)) / 2) / 100;
        }
        break;
      case 4:
        // Prokilowatt
        for (const rg of room_groups) {
          rg.requirement = Math.round(100 * ((rg.limit_value - rg.target_value) / 3 + rg.target_value)) / 100;
        }
        break;
      default:
        // no selection
        for (const rg of room_groups) {
          rg.requirement = null;
        }
        break;
    }
    room_groups = room_groups;
  };

  const filterPictureData = (obj) => {
    let filtered = { ...obj };
    delete filtered.picture_data;
    delete filtered.picture_mime;
    delete filtered.picture_filename;
    return filtered;
  };
</script>

<Table>
  <thead>
    <tr>
      <th colspan="3" scope="col">{I18n.t("lighttool.room_group.singular")}</th>
      <th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.area")}</th>
      <th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.requirement")}</th>
      {#if lighttool && v1}<th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.project_value.old")}</th>{/if}
      {#if lighttune}
        <th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.project_value.check")}</th>
        <th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.project_value.optimization")}</th><th />
      {:else}
        <th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.project_value.new")}</th>
        {#if lightcheck}<th scope="col" class="col-xs-2 text-right">{I18n.t("lighttool.room_group.project_value.check")}</th><th />{/if}
      {/if}
    </tr>
  </thead>
  <tbody>
    {#if room_groups.length > 0}
      {#each room_groups as rg}
        <tr>
          <td>
            <a href={rg.edit_path}>
              {#if lighttool && editable}
                <span title={I18n.t("lighttool.room_group.edit")} class="glyphicon glyphicon-pencil" />
              {:else if lightcheck && editable}
                <span title={I18n.t("lighttool.room_group.check_room")} class="glyphicon glyphicon-check" />
              {:else if lighttune && editable}
                {#if rg.check}
                  <span title={I18n.t("lighttool.room_group.optimize_room")} class="glyphicon glyphicon-cog" />
                {:else}
                  <span title={I18n.t("lighttool.room_group.show")} class="glyphicon glyphicon-search" />
                {/if}
              {:else}
                <span title={I18n.t("lighttool.room_group.show")} class="glyphicon glyphicon-search" />
              {/if}
            </a>
          </td>
          <td>
            {#if editable}
              {#if lighttool}
                <Confirm confirmTitle="Delete" cancelTitle="Cancel" let:confirm={confirmThis}>
                  <button class="btn-link" type="button" on:click={() => confirmThis(confirmDestroy, rg.delete_path)}
                    ><span title={I18n.t("lighttool.room_group.delete")} class="glyphicon glyphicon-trash" /></button>
                </Confirm>
              {/if}
            {/if}
          </td>
          <td class="col-xs-6">{rg.name}</td>
          <td class="text-right">{rg.area}</td>
          <td class="text-right">{rg.requirement || "-"}</td>
          {#if lighttool && v1}<td class="text-right">{rg.project_value_old || "-"}</td>{/if}
          {#if lighttune}
            <td class="text-right">{rg.project_value_check || "-"}</td>
            <td class="text-right">{rg.project_value_optimization || "-"}</td>
            <td class="text-right">{@html rg.optimization ? `<span class="glyphicon glyphicon-ok text-success" title="${I18n.t("lighttool.room_group.optimized")}" />` : ""}</td>
          {:else}
            <td class="text-right">{rg.project_value_new || "-"}</td>
            {#if lightcheck}
              <td class="text-right">{rg.project_value_check || "-"}</td>
              <td class="text-right">{@html rg.check ? `<span class="glyphicon glyphicon-ok text-success" title="${I18n.t("lighttool.room_group.checked")}" />` : ""}</td>
            {/if}
          {/if}
        </tr>
      {/each}
    {:else}
      <tr><td colspan="5"><i>{I18n.t("lighttool.room_group.empty")}</i></td></tr>
    {/if}
    {#if lighttool && editable && building.can_add_new_room_group}
      <tr>
        <td colspan="7">
          <a href={building.new_room_group_path}>
            <Button type="button" size="sm">
              <span class="glyphicon glyphicon-plus" />
              <span>{I18n.t("lighttool.room_group.add_new")}</span>
            </Button>
          </a>
        </td>
      </tr>
      <tr>&nbsp;</tr>
    {/if}
    <tr class="total-row">
      <th scope="row" colspan="3">{I18n.t("lighttool.building.total_power")}</th>
      <td class="text-right">{energyBalance.total_area || "-"}</td>
      <td class="text-right">{energyBalance.total_requirement || "-"}</td>
      {#if lighttool && v1}<td class="text-right">{energyBalance.total_project_value_old || "-"}</td>{/if}
      {#if lighttune}
        <td class="text-right">{energyBalance.total_project_value_check || "-"}</td>
        <td class="text-right">{energyBalance.total_project_value_optimization || "-"}</td><td />
      {:else}
        <td class="text-right">{energyBalance.total_project_value_new || "-"}</td>
        {#if lightcheck}<td class="text-right">{energyBalance.total_project_value_check || "-"}</td><td />{/if}
      {/if}
    </tr>
  </tbody>
</Table>

<style>
  .total-row {
    font-weight: bold;
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  td:last-child,
  th:last-child {
    padding-right: 0;
  }
  th {
    white-space: nowrap;
  }
</style>
