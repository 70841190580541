<script>
  import { getContext } from "svelte";
  import Table from "../generic/Table.svelte";
  import I18n from "../../utilities/I18n.js";

  export let energyBalance = {};
  $: console.debug(energyBalance);

  const context = getContext("context");
  const { lighttool, lightcheck, lighttune, v1 } = context;

  let columns = [
    { key: "limit_values", label: I18n.t("lighttool.room_group.limit_value"), align: "center" },
    { key: "target_values", label: I18n.t("lighttool.room_group.target_value"), align: "center" },
  ];

  if (lighttool && v1) columns.push({ key: "project_values_old", label: I18n.t("lighttool.room_group.project_value.old"), align: "center" });
  if (lighttune) {
    columns.push({ key: "project_values_check", label: I18n.t("lighttool.room_group.project_value.check"), align: "center" });
    columns.push({ key: "project_values_optimization", label: I18n.t("lighttool.room_group.project_value.optimization"), align: "center" });
  } else {
    columns.push({ key: "project_values_new", label: I18n.t("lighttool.room_group.project_value.new"), align: "center" });
    if (lightcheck) columns.push({ key: "project_values_check", label: I18n.t("lighttool.room_group.project_value.check"), align: "center" });
    if (lighttune) columns.push({ key: "project_values_optimization", label: I18n.t("lighttool.room_group.project_value.optimization"), align: "center" });
  }

  let meta = {
    columns: columns,
    rows: [
      { key: "kW", label: I18n.t("lighttool.room_group.power") },
      { key: "Wm2", label: I18n.t("lighttool.room_group.specific_power") },
      { key: "ha", label: I18n.t("lighttool.room_group.full_load_hours") },
      { key: "MWha", label: I18n.t("lighttool.room_group.energy_demand") },
      { key: "kWhm2", label: I18n.t("lighttool.room_group.specific_energy_demand") },
    ],
  };

  $: data = { ...meta, records: energyBalance };
</script>

<Table bind:data />
