<script>
  import { onMount, getContext, createEventDispatcher } from "svelte";
  import I18n from "../../utilities/I18n.js";
  import { t } from "../../utilities/general.js";

  import Modal from "sveltestrap/src/Modal.svelte";
  import ModalHeader from "sveltestrap/src/ModalHeader.svelte";
  import ModalBody from "sveltestrap/src/ModalBody.svelte";
  import ModalFooter from "sveltestrap/src/ModalFooter.svelte";
  import Button from "sveltestrap/src/Button.svelte";
  import Alert from "sveltestrap/src/Alert.svelte";
  import Confirm from "../generic/Confirm.svelte";

  import FormInput from "../generic/form/FormInput.svelte";
  import FileUpload from "../generic/form/FileUpload.svelte";

  const context = getContext("context");
  const { roomGroup } = context;
  const dispatch = createEventDispatcher();

  export let isOpen = false;
  export let luminaireTypes;
  export let luminaireTypeId;

  export let luminaireType;
  let luminaireCategories;
  let lamp_types;
  let errors = [];

  let csrfparam = "authenticity-token";
  let csrftoken = "";

  onMount(async () => {
    try {
      csrftoken = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-token")[0]
        .getAttribute("content");
      csrfparam = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-param")[0]
        .getAttribute("content");
    } catch (e) {
      console.error(e);
    }

    let response;

    response = await fetch(roomGroup.luminaire_categories_path);
    luminaireCategories = await response.json();

    response = await fetch(roomGroup.lamp_types_path);
    lamp_types = await response.json();
  });

  const newLuminaireType = async () => {
    const response = await fetch(roomGroup.new_luminaire_type_path);
    const data = await response.json();
    return data;
  };

  const toggle = () => {
    isOpen = !isOpen;
    errors = [];
  };

  const handleFormSubmit = () => {
    errors = [];
    const form = document.querySelector("form#luminairetypeform");
    const request = new XMLHttpRequest();

    if (luminaireType.id) {
      request.open("PUT", roomGroup.update_luminaire_type_path);
    } else {
      request.open("POST", roomGroup.create_luminaire_type_path);
    }

    request.onload = (e) => {
      if (request.status === 200) {
        const data = JSON.parse(request.response);
        data.lighttool_luminaire_category_id = parseInt(data.lighttool_luminaire_category_id);
        data.lighttool_lamp_type_id = parseInt(data.lighttool_lamp_type_id);
        if (luminaireType.id) {
          luminaireTypes = luminaireTypes.map((l) => {
            if (l.id === luminaireType.id) return data;
            else return l;
          });
        } else luminaireTypes = [...luminaireTypes, data];
        luminaireTypes = luminaireTypes.sort(luminaireTypeSort);
        luminaireTypeId = data.id;
        dispatch("change");
        toggle();
      } else
        try {
          let res = JSON.parse(request.response);
          errors = res.error;
        } catch (e) {
          errors = [I18n.t("lighttool.application.alert.saving_failed")];
        }
    };
    request.send(new FormData(form));
  };

  const confirmDestroy = async (itemURL) => {
    const response = await destroy(itemURL, { _method: "delete" }, "POST");

    if (response.status === 200) {
      luminaireTypes = luminaireTypes.filter((lt) => lt.id !== luminaireType.id);
      dispatch("change");
      toggle();
    } else
      try {
        let res = JSON.parse(response);
        errors = res.error;
      } catch (e) {
        errors = [I18n.t("lighttool.application.alert.deleting_failed")];
      }
  };

  const destroy = async (url = "", data = {}, method) => {
    let csrfparam = "authenticity-token";
    let csrftoken;
    try {
      csrftoken = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-token")[0]
        .getAttribute("content");
      csrfparam = Array.from(document.getElementsByTagName("meta"))
        .filter((m) => m.getAttribute("name") === "csrf-param")[0]
        .getAttribute("content");
    } catch (e) {
      console.error(e);
    }

    var formdata = encodeURIComponent(csrfparam) + "=" + encodeURIComponent(csrftoken);
    for (const key in data) {
      formdata += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
    }

    const response = await fetch(url, {
      method: method,
      headers: { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8" },
      body: formdata,
    });
    return await response;
  };

  const handleFileInput = (e) => {
    if (e.target && e.target.files.length > 0) luminaireType.datasheet_upload_filename = e.target.files[0].name;
  };

  const luminaireTypeSort = (a, b) => {
    if (t(a.full_name) < t(b.full_name)) return -1;
    if (t(a.full_name) > t(b.full_name)) return 1;
    return 0;
  };

  $: !luminaireType ? (luminaireType = newLuminaireType()) : null;

  $: luminaireType.full_name = [
    luminaireType.lighttool_luminaire_category_id ? luminaireCategories.filter((c) => c.id == luminaireType.lighttool_luminaire_category_id)[0].name.de : "",
    luminaireType.name,
    luminaireType.operating_wattage ? `${luminaireType.operating_wattage} W` : luminaireType.connected_wattage ? `${luminaireType.connected_wattage} W` : "",
  ].join(" ");
</script>

<Modal {isOpen} {toggle}>
  <form id="luminairetypeform" on:submit|preventDefault={handleFormSubmit} enctype="multipart/form-data">
    <ModalHeader>
      <h4>{luminaireType.id ? I18n.t("lighttool.luminaire.edit_type") : I18n.t("lighttool.luminaire.add_new_type")}</h4>
      <h5>{luminaireType.full_name}</h5>
    </ModalHeader>
    {#if luminaireType.id}
      <Alert color="info">{@html I18n.t("lighttool.luminaire.edit_warning")}</Alert>
    {/if}
    {#each errors as e}
      <div class="alert alert-warning">{e}</div>
    {/each}
    <ModalBody>
      <FormInput hidden name="luminaire_type[id]" bind:value={luminaireType.id} />
      <FormInput
        fullwidth
        type="select"
        name="luminaire_type[lighttool_luminaire_category_id]"
        label={I18n.t("lighttool.luminaire.luminaire_category")}
        bind:value={luminaireType.lighttool_luminaire_category_id}
        options={luminaireCategories} />
      <FormInput fullwidth name="luminaire_type[name]" label={I18n.t("lighttool.luminaire.name")} bind:value={luminaireType.name} />
      <FormInput fullwidth name="luminaire_type[producer]" label={I18n.t("lighttool.luminaire.producer")} bind:value={luminaireType.producer} />
      <FormInput
        fullwidth
        type="select"
        name="luminaire_type[lighttool_lamp_type_id]"
        label={I18n.t("lighttool.luminaire.lamp_type")}
        bind:value={luminaireType.lighttool_lamp_type_id}
        options={lamp_types} />
      <FormInput
        fullwidth
        type="number"
        step="0.1"
        name="luminaire_type[connected_wattage]"
        label={I18n.t("lighttool.luminaire.connected_wattage")}
        bind:value={luminaireType.connected_wattage}
        addon="W"
        tooltip={I18n.t("lighttool.luminaire.connected_wattage_tooltip")} />
      <FormInput
        fullwidth
        type="number"
        name="luminaire_type[luminous_flux]"
        label={I18n.t("lighttool.luminaire.luminous_flux")}
        bind:value={luminaireType.luminous_flux}
        addon="lm" />
      <FormInput
        fullwidth
        type="number"
        step="0.1"
        name="luminaire_type[operating_wattage]"
        label={I18n.t("lighttool.luminaire.operating_wattage")}
        bind:value={luminaireType.operating_wattage}
        addon="W"
        tooltip={I18n.t("lighttool.luminaire.operating_wattage_tooltip")} />
      {#if luminaireType.datasheet_url}
        <div class="form-group">
          <a href={luminaireType.datasheet_url} target="_blank"
            ><Button type="button"><span class="glyphicon glyphicon-file" /> {I18n.t("lighttool.asset.name.datasheet")} {I18n.t("lighttool.asset.download")}</Button></a>
        </div>
      {/if}
      <FileUpload name="luminaire_type[datasheet_data]" style="margin-top: 10px" on:input={handleFileInput} }>
        <div class="datasheet-placeholder">
          {#if luminaireType.datasheet_upload_filename}
            <span class="glyphicon glyphicon-open-file" /><br />{luminaireType.datasheet_upload_filename}
          {:else if luminaireType.datasheet_url}
            <span class="glyphicon glyphicon-transfer" /><br />{I18n.t("lighttool.asset.name.datasheet")}&nbsp;{I18n.t("lighttool.asset.replace")}
          {:else}
            <span class="glyphicon glyphicon-open" /><br />{I18n.t("lighttool.asset.name.datasheet")}&nbsp;{I18n.t("lighttool.asset.upload")}
          {/if}
        </div>
      </FileUpload>
    </ModalBody>
    <ModalFooter>
      {#if luminaireType.id && luminaireType.deletable}
        <span class="delete-button">
          <Confirm confirmTitle="Delete" cancelTitle="Cancel" let:confirm={confirmThis}>
            <button class="btn-link" type="button" on:click={() => confirmThis(confirmDestroy, luminaireType.delete_path)}
              ><span title={I18n.t("lighttool.luminaire.luminaire_type.delete")} class="glyphicon glyphicon-trash" /></button>
          </Confirm>
        </span>
      {/if}
      <Button color="primary" type="submit">{I18n.t("lighttool.application.save_and_close")}</Button>
      <Button color="secondary" type="button" on:click={toggle}>{I18n.t("lighttool.application.abort")}</Button>
    </ModalFooter>

    <input type="hidden" name={csrfparam} value={csrftoken} />
  </form>
</Modal>

<style>
  .datasheet-placeholder {
    border: 1px solid grey;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    text-align: center;
  }
  .datasheet-placeholder > .glyphicon {
    font-size: 2.5em;
    margin-bottom: 0.25em;
  }
  .delete-button {
    float: left;
  }
</style>
